import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container my-8" }
const _hoisted_2 = {
  key: 0,
  class: "bg-white border shadow rounded-md h-18 flex flex-row items-center justify-between px-5 mt-4"
}
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = { class: "flex flex-col ml-3" }
const _hoisted_5 = {
  "data-dusk": "journey-type",
  class: "font-medium text-gray-900"
}
const _hoisted_6 = {
  "data-dusk": "journey-laterality",
  class: "text-sm text-gray-600"
}
const _hoisted_7 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_8 = { class: "bg-white border shadow rounded-md mt-6" }
const _hoisted_9 = { class: "px-5 pb-8 pt-6" }
const _hoisted_10 = { class: "text-lg font-medium" }
const _hoisted_11 = { class: "flex flex-col" }
const _hoisted_12 = {
  "data-dusk": "encounter-type",
  class: "font-medium text-gray-900"
}
const _hoisted_13 = {
  "data-dusk": "encounter-last_updated_at",
  class: "text-sm text-gray-600"
}
const _hoisted_14 = {
  key: 0,
  class: "font-medium text-gray-900"
}
const _hoisted_15 = {
  key: 0,
  class: "flex flex-row items-center space-x-2"
}
const _hoisted_16 = { class: "text-gray-600 text-sm" }
const _hoisted_17 = { class: "flex flex-row items-center justify-end space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseBanner = _resolveComponent("BaseBanner")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_IconGlyph = _resolveComponent("IconGlyph")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_EndPrescriptionModal = _resolveComponent("EndPrescriptionModal")!
  const _component_MyopiaPrognosisModal = _resolveComponent("MyopiaPrognosisModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.journey && _ctx.journey.ended_at)
      ? (_openBlock(), _createBlock(_component_BaseBanner, {
          key: 0,
          color: "gray",
          label: `${_ctx.$t('custom.menicon.lens-ordering.cancel.status')}: ${_ctx.formatDate(_ctx.journey.ended_at)}`
        }, null, 8, ["label"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ButtonLink, {
        to: { name: 'patient-prescriptions', params: { patientId: _ctx.patientId } },
        color: "clear",
        size: "small",
        "left-icon": "arrow-left"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.prescriptions.all')), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      (_ctx.journey)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_IconGlyph, {
                name: "bill-glyph-16",
                color: _ctx.journey.ended_at ? 'gray' : 'primary',
                size: "small"
              }, null, 8, ["color"]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.journey.type || _ctx.$t('custom.menicon.prescriptions.no-product')), 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.journey.laterality ? _ctx.$t(`platform.eyes.${_ctx.journey.laterality}-eye`) : _ctx.$t('platform.eyes.both-eyes')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (!_ctx.journey.ended_at && _ctx.journey.type)
                ? (_openBlock(), _createBlock(_component_BasePopover, {
                    key: 0,
                    position: "right"
                  }, {
                    button: _withCtx(() => [
                      _createVNode(_component_BaseButton, {
                        color: "ghost",
                        "right-icon": "chevron-down"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.options')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    menu: _withCtx(() => [
                      (_ctx.myopiaPrognosisFeatureFlag)
                        ? (_openBlock(), _createBlock(_component_PopoverButton, {
                            key: 0,
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMyopiaPrognosisModal = true))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.myopia-prognosis.title')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.journey.ended_at && _ctx.journey.type)
                        ? (_openBlock(), _createBlock(_component_PopoverButton, {
                            key: 1,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showEndJourneyModal = true))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.lens-ordering.cancel.title')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.showWorkflows)
                ? (_openBlock(), _createBlock(_component_BasePopover, {
                    key: 1,
                    "data-dusk": "encounter-menu",
                    position: "left"
                  }, {
                    button: _withCtx(() => [
                      _createVNode(_component_BaseButton, {
                        "data-dusk": "start-encounter",
                        "right-icon": "chevron-down"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.prescriptions.new-encounter')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    menu: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.encounterTypes, (encounterType) => {
                        return (_openBlock(), _createBlock(_component_PopoverButton, {
                          key: encounterType.type,
                          style: _normalizeStyle(`order: ${encounterType.order}`),
                          onClick: ($event: any) => (_ctx.newEncounter(encounterType))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t(`custom.menicon.encounter.type.${encounterType.type}`)), 1)
                          ]),
                          _: 2
                        }, 1032, ["style", "onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('custom.menicon.encounter.encounters')), 1)
        ]),
        _createVNode(_component_DataTable, {
          style: {"border-spacing":"0","border-collapse":"collapse !important"},
          class: _normalizeClass(["w-full", { 'border-b': _ctx.total && _ctx.total > _ctx.perPage }]),
          "custom-heading-classes": "text-sm text-gray-500 pb-2",
          "cell-style-classes": "border-t rounded-b-md",
          columns: [
            { name: 'last_updated_at', label: _ctx.$t('platform.common.last-activity'), sortable: true },
            {
              name: 'completed_at',
              label: _ctx.$t('platform.common.status'),
              align: 'left'
            },
            { name: 'last_activity', label: _ctx.$t('platform.common.clinician'), align: 'left' },
            { name: 'notes', label: _ctx.$t('custom.menicon.encounter.additional-information'), align: 'left' },
            { name: 'actions', label: '', align: 'right' }
          ],
          sort: _ctx.sort,
          rows: _ctx.encounters,
          "display-value-in-cell": false,
          onSort: _ctx.updateSort
        }, {
          "cell.last_updated_at": _withCtx(({ row, value }) => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t(`custom.menicon.encounter.type.${row.type}`)), 1),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.getEncounterDate(value)), 1)
            ])
          ]),
          "cell.completed_at": _withCtx(({ value }) => [
            value
              ? (_openBlock(), _createBlock(_component_MkBadge, {
                  key: 0,
                  color: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.complete')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_MkBadge, {
                  key: 1,
                  color: "gray"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.encounter.draft')), 1)
                  ]),
                  _: 1
                }))
          ]),
          "cell.last_activity": _withCtx(({ value }) => [
            (value && value.user)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(value.user.given_name) + " " + _toDisplayString(value.user.family_name), 1))
              : _createCommentVNode("", true)
          ]),
          "cell.notes": _withCtx(({ row }) => [
            (!_ctx.journey.laterality && row.type === 'lens-reorder' && row.laterality)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t(`custom.menicon.lens-ordering.notes-${row.laterality}-reorder`)), 1),
                  _createVNode(_component_BaseTooltip, {
                    class: "-mt-1",
                    position: "below",
                    "horizontal-alignment": "right"
                  }, {
                    target: _withCtx(() => [
                      _createVNode(_component_BaseIcon, {
                        class: "text-gray-400 hover:text-gray-900 focus:text-gray-900 stroke-1.5",
                        height: "12px",
                        name: "info"
                      })
                    ]),
                    content: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('custom.menicon.lens-ordering.notes-tooltip-message')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          "cell.actions": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_17, [
              (row.completed_at)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (row.order)
                      ? (_openBlock(), _createBlock(_component_BaseButton, {
                          key: 0,
                          color: "clear",
                          class: "text-secondary-600",
                          onClick: ($event: any) => (_ctx.downloadReceipt(row.order.id))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BaseIcon, {
                              name: "download",
                              height: "12px",
                              class: "mr-2 stroke-1.5"
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('custom.menicon.prescriptions.receipt')), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ButtonLink, {
                      color: "ghost",
                      to: {
                    name: 'patient-encounter',
                    params: {
                      type: row.type,
                      patientId: _ctx.patientId,
                      journeyId: _ctx.journeyId,
                      encounterId: row.id
                    }
                  }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.view')), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_BaseButton, {
                      color: "clear",
                      class: "text-secondary-600",
                      onClick: ($event: any) => (_ctx.deleteEncounterId = row.id)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.delete')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_ButtonLink, {
                      color: "ghost",
                      to: {
                    name: 'patient-encounter',
                    params: {
                      patientId: _ctx.patientId,
                      journeyId: row.journey_id,
                      encounterId: row.id,
                      type: row.type
                    }
                  }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.continue')), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ], 64))
            ])
          ]),
          _: 1
        }, 8, ["class", "columns", "sort", "rows", "onSort"]),
        (_ctx.total && _ctx.total > _ctx.perPage)
          ? (_openBlock(), _createBlock(_component_BasePagination, {
              key: 0,
              class: "border-none mt-0",
              "sm-pages-limit": 8,
              "model-value": _ctx.page,
              "items-name": _ctx.$t('custom.menicon.prescriptions.prescriptions').toLowerCase(),
              "page-size": _ctx.perPage,
              length: _ctx.total,
              "onUpdate:modelValue": _ctx.changePage
            }, null, 8, ["model-value", "items-name", "page-size", "length", "onUpdate:modelValue"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.deleteEncounterId)
        ? (_openBlock(), _createBlock(_component_ActionModal, {
            key: 1,
            icon: "trash",
            "icon-color": "white",
            "icon-bg-color": "danger-500",
            danger: "",
            "show-close": false,
            title: _ctx.$t('custom.menicon.prescriptions.drafts.delete.title'),
            body: 
          _ctx.encounters.length === 1
            ? _ctx.$t('custom.menicon.prescriptions.drafts.delete.im-message')
            : _ctx.$t('custom.menicon.prescriptions.drafts.delete.message')
        ,
            actions: _ctx.deleteDraftModalActions,
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteEncounterId = null))
          }, null, 8, ["title", "body", "actions"]))
        : _createCommentVNode("", true),
      (_ctx.showEndJourneyModal)
        ? (_openBlock(), _createBlock(_component_EndPrescriptionModal, {
            key: 2,
            "patient-id": _ctx.patientId,
            "journey-id": _ctx.journeyId,
            onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showEndJourneyModal = false)),
            onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showEndJourneyModal = false)),
            onTerminate: _ctx.cancelJourney
          }, null, 8, ["patient-id", "journey-id", "onTerminate"]))
        : _createCommentVNode("", true),
      (_ctx.showMyopiaPrognosisModal)
        ? (_openBlock(), _createBlock(_component_MyopiaPrognosisModal, {
            key: 3,
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showMyopiaPrognosisModal = false))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}