
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import MyopiaPrognosisModal from '@/custom/menicon/components/MyopiaPrognosisModal.vue';
import {
  BaseButton,
  ActionModal,
  ButtonLink,
  DataTable,
  BasePopover,
  PopoverButton,
  BasePagination,
  BaseIcon,
  IconGlyph,
  BaseBanner
} from '@/lib/components';
import {
  PatientJourneyService,
  MeniconOrderService,
  JourneyEncounterService,
  JourneyTypesService
} from '@/services/api';
import { Encounter, PaginatedResponse, EncounterType, JourneyType, Journey } from '@/models';
import axios, { CancelTokenSource } from 'axios';
import { FEATURES } from '@/constants';
import { IModalAction } from '@/lib';
import EndPrescriptionModal from '@/custom/menicon/components/EndPrescriptionModal.vue';
import BaseTooltip from '@/lib/components/Tooltip/BaseTooltip.vue';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useProgressStore } from '@/stores/progress.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },
    journeyId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseTooltip,
    BaseIcon,
    EndPrescriptionModal,
    BaseButton,
    ActionModal,
    ButtonLink,
    DataTable,
    MyopiaPrognosisModal,
    BasePopover,
    PopoverButton,
    BasePagination,
    IconGlyph,
    BaseBanner
  }
})
export default class PrescriptionDetailsPage extends Vue {
  patientId!: string;
  journeyId!: string;
  progressStore = useProgressStore();
  notificationStore = useNotificationStore();
  journey: Journey | null = null;
  encounters: Encounter[] = [];
  showMyopiaPrognosisModal = false;
  deleteEncounterId: string | null = null;
  myopiaPrognosisFeatureFlag = isFeatureFlagEnabled(FEATURES.MYOPIA_PROGNOSIS);
  showEndJourneyModal = false;
  orderService = new MeniconOrderService(this.patientId);
  journeyService = new PatientJourneyService(this.patientId);
  journeyEncounterService = new JourneyEncounterService(this.patientId, this.journeyId);
  journeyTypesService = new JourneyTypesService();
  perPage = 10;
  total = 0;
  request: CancelTokenSource = axios.CancelToken.source();
  journeyTypes: JourneyType[] = [];

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || '-last_updated_at';
  }

  get deleteDraftModalActions(): Array<IModalAction> {
    return [
      {
        label: this.$t('custom.menicon.prescriptions.drafts.delete.label') as string,
        color: 'danger',
        onClick: () => this.deleteEncounter(this.deleteEncounterId as string)
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'ghost',
        onClick: () => (this.deleteEncounterId = null)
      }
    ];
  }

  get encounterTypes(): EncounterType[] {
    return (
      this.journeyTypes
        .find((type: JourneyType) => type.type === this.journey?.type)
        ?.encounter_types?.filter((type: EncounterType) => type.show) || ([] as EncounterType[])
    );
  }

  get showWorkflows(): boolean {
    return !!(!this.journey?.ended_at && this.encounterTypes.flatMap((type) => type.available_forms).length);
  }

  async created() {
    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.onRouteQueryChange();
      } else {
        unWatchRoute();
      }
    });
    await this.fetchData();
  }

  beforeUnmount() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchData() {
    try {
      this.progressStore.startProgress();
      await this.fetchJourney();
      await this.fetchEncounters();
      await this.fetchJourneyTypes();
      this.progressStore.finishProgress();
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.fetch-data')
        });
      }
    }
  }

  async cancelJourney() {
    this.showEndJourneyModal = false;
    this.notificationStore.snackbar = {
      label: this.$t('custom.menicon.lens-ordering.cancel.status')
    };
    await this.fetchData();
  }

  async onRouteQueryChange() {
    try {
      this.progressStore.startProgress();
      await this.fetchEncounters();
      this.progressStore.finishProgress();
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.menicon.encounter.error.index')
        });
      }
    }
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async fetchJourney() {
    this.journey = await this.journeyService.fetch(this.journeyId, {
      cancelToken: this.request.token
    });
  }

  async fetchEncounters() {
    const response = (await this.journeyEncounterService.index({
      params: {
        include: 'order,user',
        sort: this.sort,
        page: this.page,
        per_page: this.perPage
      },
      cancelToken: this.request.token
    })) as PaginatedResponse<Encounter[]>;
    this.encounters = response.data;
    this.total = response.meta.total;
  }

  async fetchJourneyTypes() {
    this.journeyTypes = (await this.journeyTypesService.index({ cancelToken: this.request.token })).data;
  }

  async updateSort(sort: string) {
    await this.$router.replace({
      path: this.$route.path,
      query: {
        sort
      }
    });
  }

  async newEncounter(encounterType: EncounterType) {
    const encounter = (
      await this.journeyEncounterService.create({
        type: encounterType.type
      })
    ).data;
    await this.$router.push({
      name: 'patient-encounter',
      params: {
        patientId: this.patientId,
        journeyId: this.journeyId,
        encounterId: encounter.id
      }
    });
  }

  async deleteEncounter(encounterId: string) {
    try {
      await this.journeyEncounterService.delete(encounterId);
      if (this.encounters.length === 1) {
        await this.$router.replace({
          name: 'patient-prescriptions',
          params: {
            patientId: this.patientId
          }
        });
      } else {
        await this.fetchEncounters();
      }
      await this.notificationStore.addSuccessNotification({
        title: this.$t('custom.menicon.prescriptions.drafts.delete.success')
      });
      this.deleteEncounterId = null;
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.menicon.prescriptions.drafts.delete.error')
      });
    }
  }

  formatDate(isoDate: string) {
    return this.$d(new Date(isoDate), 'shortMonth');
  }

  getEncounterDate(date: string): string {
    const d = dayjs(date).calendar(dayjs(), {
      sameDay: `[${this.$t('platform.common.date-time.today')}], LT`, // The same day ( Today, 2:30 AM )
      nextDay: `[${this.$t('platform.common.date-time.tomorrow')}], LT`, // The next day ( Tomorrow, 2:30 AM )
      nextWeek: 'll', // The next week ( 7 Oct 2011 )
      lastDay: `[${this.$t('platform.common.date-time.yesterday')}], LT`, // The day before ( Yesterday, 2:30 AM )
      lastWeek: 'll', // Last week ( 7 Oct 2011)
      sameElse: 'll' // Everything else ( 7 Oct 2011 )
    });
    const relativeDate = dayjs(date).from(dayjs());
    if (dayjs().diff(dayjs(date), 'day') > 1) {
      return `${d} (${relativeDate})`;
    }
    return d;
  }

  async downloadReceipt(orderId: string) {
    const downloadUrl = await this.orderService.getReceipt(orderId);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'receipt.pdf'); // any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
